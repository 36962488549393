import { combineReducers, configureStore } from '@reduxjs/toolkit';

import cartReducer from './slices/cartSlice';
import specialHeaderReducer from './slices/specialHeaderSlice'

const rootReducer = combineReducers({
    cart: cartReducer,
    specialHeader: specialHeaderReducer,
});

export const setupStore = (preloadedState) => {
    return configureStore({
        reducer: rootReducer,
        preloadedState,
    });
};

// exported for testing
export default rootReducer;