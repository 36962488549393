import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
// import { fetchQuery } from 'react-relay';
// import { graphql } from 'react-relay';

// import environment from '../../environment';

const initialState = {
    specialHeader: null,
  };

// WAITING FOR BACKEND
// const specialHeaderQuery = graphql`
//   query special {
//     viewer {
//      ... 
//     }
//   }
// `;

// export const fetchSpecialHeaderName = createAsyncThunk(
//   'navbar/specialHeaderQuery',
//   async () => {
//     try {
//       // ignore warning, without await it does not work
//       const response = await fetchQuery(environment, specialHeaderQuery, {});
//       return response?.viewer?.WAITFORBACKEND || null
//     } catch (error) {
//       return null;
//     }
//   }
// );

export const specialHeaderSlice = createSlice({
  name: 'specialHeader',
  initialState,
//   extraReducers: (builder) => {
//     builder.addCase(fetchSpecialHeaderName.fulfilled, (state, action) => {
//       state.specialHeader = action.payload;
//     });
//   },
});

// TODO: map through special type headers and return classname, use selector in header
export const selectSpecialHeaderName = (state) => state.cart.specialHeader;

export default specialHeaderSlice.reducer;
