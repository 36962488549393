import React, { Fragment, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { createFragmentContainer, graphql } from 'react-relay';
import { FormattedMessageWrappedInSpan } from '../components/misc';

import DvdLabelDialog from '../components/content/dialogs/DvdLabelDialog';
import PhotoBookShippingInformationDialog from '../components/content/dialogs/PhotoBookShippingInformationDialog';
import MobileContainer from '../components/misc/MobileContainer';
import StickyStayInPlaceContainer from '../components/misc/StickyStayInPlaceContainer';
import {
  CartAdditionalProductDvd,
  CartOverviewItem,
  CartTotal,
} from '../components/content/cart-items';
import CartNavigationSteps from '../components/content/cart-items/CartNavigationSteps';
import { CartQuickCheckout } from '../components/content/cart-quick-checkout';

import { useSimpleForm, useLoadingReducer, useNotificationDialogContext } from '../hooks';
import { getPhotoDvd } from '../utils/common';
import { trackEvent } from '../utils/ga-tracking';
import { UserConsumer } from '../components/context/UserContext';
import {
  useOptimisticAddItemToCart,
  useOptimisticRemoveItemFromCart,
  useOptimisticClearCart,
} from '../store/slices/cartSlice';

import {
  AddFotoCdToCartMutation,
  ClearCartMutation,
  DeleteLineItemFromCartMutation,
} from '../mutations';

import {
  PHOTO_DVD,
  PHOTO_BOOK,
  PHOTO_DVD_EXPLANATION_MSG,
  PHOTO_BOOK_SHIPPING_CHARGES_MSG,
} from '../utils/variables';

////////////////////////////////////////////
// TODO MISSING TESTS
////////////////////////////////////////////

function CartPage(props) {
  const { cart } = props.viewer;

  const [loadingItems, dispatch, isObjectLoading] = useLoadingReducer();
  const [showDvdLabelDialog, setShowDvdLabelDialog] = useState(false);
  const [dvdFields, handleDvdFieldsChange] = useSimpleForm([
    { name: 'lineOne' },
    { name: 'lineTwo', initialValue: cart.lineItems[0] ? cart.lineItems[0].product.event.name : "" },
    { name: 'lineThree' },
    { name: 'lineFour' },
  ]);
  const [showPaymentNotification, setShowPaymentNotification] = useState(false);

  const notificationContext = useNotificationDialogContext();
  const addOptimisticItemToCart = useOptimisticAddItemToCart();
  const removeOptimisticItemFromCart = useOptimisticRemoveItemFromCart();
  const clearOptimisticCart = useOptimisticClearCart();

  const additionalDvd = useMemo(() => {
    // Check if the dvd is an additional product
    return getPhotoDvd(cart.additionalProducts);
  }, [cart.additionalProducts]);

  function addFotoCdToCart(productId, dvdText) {
    addOptimisticItemToCart();
    dispatch({ type: 'add', payload: productId });

    AddFotoCdToCartMutation.commit(
      props.relay.environment,
      {
        productId,
        textLine1: dvdText.lineOne,
        textLine2: dvdText.lineTwo,
        textLine3: dvdText.lineThree,
        textLine4: dvdText.lineFour,
      },
      (error, cart) => {
        if (error) {
          removeOptimisticItemFromCart();
        }
        dispatch({ type: 'remove', payload: productId });
      }
    );
  }

  function removeItemFromCart(lineItemId) {
    dispatch({ type: 'add', payload: lineItemId });

    const { cart } = props.viewer;

    // If the last item remaining in the cart should be removed, call the 'clearCart'
    // callback immediately. Will NOT be triggered if multiple items are deleted from the
    // cart at the same time.
    if (cart.lineItems.length === 1) {
      clearCart();
    } else {
      removeOptimisticItemFromCart();
      DeleteLineItemFromCartMutation.commit(
        props.relay.environment,
        lineItemId,
        (error, updatedCart) => {
          if (updatedCart) {
            // A DVD can not be bought, if no other products (Foto-Flat, Single Photo)
            // are in the updatedCart. This means that if the only product in the updatedCart is a DVD,
            // the updatedCart should be cleared.
            if (updatedCart.lineItems.length === 1) {
              if (updatedCart.lineItems[0].product.type === PHOTO_DVD) {
                clearCart();
              }
            }

            if (updatedCart.lineItems.length === 0) {
              // This case occurs when multiple items are deleted from the cart at the
              // SAME TIME and the first 'if' case was not triggered.
              clearCart();
            }
          } else {
            // This case occurs if the backend could not found the cart
            clearCart();
          }
          dispatch({ type: 'remove', payload: lineItemId });
        }
      );
    }
  }

  function clearCart() {
    clearOptimisticCart();
    ClearCartMutation.commit(props.relay.environment, props.viewer.cart.id);
  }

  function handleHelpClick(productType) {
    switch (productType) {
      case PHOTO_DVD:
        notificationContext.onOpenNotificationDialog(PHOTO_DVD_EXPLANATION_MSG);
    }
  }

  function handleSubtitleHelpClick(productType) {
    switch (productType) {
      case PHOTO_BOOK:
        notificationContext.onOpenNotificationDialog(PHOTO_BOOK_SHIPPING_CHARGES_MSG);
    }
  }

  function goToCheckout() {
    trackEvent('Checkout', 'Start checkout', 'Proceed to checkout from cart overview');
    props.history.push('/checkout');
  }

  function returnAllEventNames(lineItems) {
    const eventNames = [];
    lineItems.map((item) => {
      if (item.product.event.name) {
        eventNames.push(item.product.event.name);
      }
    });
    return [...new Set(eventNames)];
  }

  function togglePaymentNotification() {
    setShowPaymentNotification((current) => !current);
  }

  return (
    <UserConsumer>
      {({ isLoggedIn }) => (
        <Fragment>
          <div className="container-960 container-page ">
            <CartNavigationSteps
              currentStep={1}
              history={props.history}
              isOrder={Boolean(cart.order)}
              isLoggedInNow={isLoggedIn}
            />
            <div className="row cart-row cart">
              <div className="col-sm-10 col-xs-16 cart-left ">
                <div className="cart-overview cart-field cart-light-field">
                  <h1>
                    <FormattedMessageWrappedInSpan
                      id="cartPage.overviewTitle"
                      defaultMessage="Your cart"
                    />
                  </h1>

                  <div className="cart-page-items-list">
                    {cart.lineItems.map((li) => {
                      return (
                        <CartOverviewItem
                          key={li.id}
                          eventNames={
                            li.product.type === PHOTO_DVD
                              ? returnAllEventNames(cart.lineItems)
                              : ''
                          }
                          isLoading={isObjectLoading(li.id)}
                          lineItem={li}
                          onHelpClick={() => handleHelpClick(li.product.type)}
                          onDeleteClick={() => removeItemFromCart(li.id)}
                          onSubtitleHelpClick={() =>
                            handleSubtitleHelpClick(li.product.type)
                          }
                        />
                      );
                    })}
                  </div>
                </div>
                {additionalDvd && (
                  <div className=" cart-field cart-dark-field cart-additional-prducts">
                    <h1>
                      <FormattedMessageWrappedInSpan
                        id="cartPage.additionalProductsNewTitle"
                        defaultMessage="You can also buy"
                      />
                    </h1>
                    <CartAdditionalProductDvd
                      additionalProduct={additionalDvd}
                      isLoading={isObjectLoading(additionalDvd.id)}
                      onAddToCartClick={() => setShowDvdLabelDialog(true)} // Before adding the DVD to the cart, the user can customize it
                      onHelpClick={() => handleHelpClick(additionalDvd.type)}
                      eventNames={returnAllEventNames(cart.lineItems)}
                    />
                  </div>
                )}
              </div>
              <div className="col-sm-6 col-xs-16 cart-right ">
                {cart.lineItems && (
                  <CartTotal
                    totalPrice={cart.totalPrice}
                    lineItems={cart.lineItems}
                    onOpenPaymentNotification={togglePaymentNotification}
                    componentVersion={'extended'}
                  />
                )}
                <CartQuickCheckout
                  onGoToCheckout={goToCheckout}
                  isOrder={Boolean(cart.order)}
                  isLoggedIn={isLoggedIn}
                  isDesktop={true}
                  stepNumber={-1}
                />
              </div>
            </div>
          </div>
          <MobileContainer>
            <StickyStayInPlaceContainer marginTop={20}>
              <CartQuickCheckout
                onGoToCheckout={goToCheckout}
                isLoggedIn={isLoggedIn}
                isOrder={Boolean(cart.order)}
                stepNumber={-1}
              />
            </StickyStayInPlaceContainer>
          </MobileContainer>
          <DvdLabelDialog
            open={showDvdLabelDialog}
            dvdText={dvdFields}
            onTextChange={handleDvdFieldsChange}
            onRequestClose={() => setShowDvdLabelDialog(false)}
            onAcceptClick={() => {
              setShowDvdLabelDialog(false);
              addFotoCdToCart(additionalDvd.id, dvdFields);
            }}
          />
          <PhotoBookShippingInformationDialog
            open={showPaymentNotification}
            onRequestClose={togglePaymentNotification}
          />
        </Fragment>
      )}
    </UserConsumer>
  );
}

CartPage.propTypes = {
  // react-router props
  history: PropTypes.object,

  // relay props
  relay: PropTypes.object,
  viewer: PropTypes.object,
};

export default createFragmentContainer(CartPage, {
  viewer: graphql`
    fragment CartPage_viewer on Viewer {
      cart {
        order {
          orderid
        }
        additionalProducts {
          id
          type
          ...CartAdditionalProductDvd_additionalProduct
        }
        id
        lineItems {
          id
          product {
            event {
              name
            }
            type
          }
          price
          productParams {
            ... on SingleFotoParams {
              foto {
                sgId
              }
            }
            ... on FotoFlatParams {
              startnumber
            }
          }

          ...CartOverviewItem_lineItem
        }
        totalPrice
      }
    }
  `,
});
