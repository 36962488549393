/**
 * @flow
 * @relayHash 1a994298bfcf77acf840f0867b2b549e
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type cartSliceQueryVariables = {||};
export type cartSliceQueryResponse = {|
  +viewer: ?{|
    +cart: ?{|
      +lineItems: ?$ReadOnlyArray<?{|
        +id: string
      |}>,
      +id: string,
    |}
  |}
|};
export type cartSliceQuery = {|
  variables: cartSliceQueryVariables,
  response: cartSliceQueryResponse,
|};
*/


/*
query cartSliceQuery {
  viewer {
    cart {
      lineItems {
        id
      }
      id
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "cart",
  "storageKey": null,
  "args": null,
  "concreteType": "Cart",
  "plural": false,
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "lineItems",
      "storageKey": null,
      "args": null,
      "concreteType": "LineItem",
      "plural": true,
      "selections": [
        (v0/*: any*/)
      ]
    },
    (v0/*: any*/)
  ]
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "cartSliceQuery",
    "type": "RootQueryType",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/)
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "cartSliceQuery",
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v0/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "cartSliceQuery",
    "id": null,
    "text": "query cartSliceQuery {\n  viewer {\n    cart {\n      lineItems {\n        id\n      }\n      id\n    }\n    id\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'd2ff8cac2428f80db5bd8ca4ec396247';

module.exports = node;
