import React, { Fragment, Component } from 'react';
import { createRefetchContainer, graphql } from 'react-relay';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { injectIntl, defineMessages } from 'react-intl';
import classNames from 'classnames';

import Resizer from 'react-image-file-resizer';
import { Cookies } from 'react-cookie';

import DateFnsUtils from '@date-io/date-fns';
import { Tooltip, CircularProgress } from '@material-ui/core';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';

import deLocale from 'date-fns/locale/de';
import enLocale from 'date-fns/locale/en-US';
import frLocale from 'date-fns/locale/fr';
import nlLocale from 'date-fns/locale/nl';
import itLocale from 'date-fns/locale/it';
import esLocale from 'date-fns/locale/es';
import zhLocale from 'date-fns/locale/zh-CN';
import ptLocale from 'date-fns/locale/pt';

import withRequestHandler from '../../hoc/withRequestHandler';
import GDPRHint from '../GDPRHint';

import SearchInstructionsDialog from '../dialogs/SearchInstructionsDialog';
import FaceSearchIDCookieDialog from '../dialogs/FaceSearchIDCookieDialog';
import ExtendedSearchInstructionsDialog from '../dialogs/ExtendedSearchInstructionsDialog';
import { FaceRecognitionUpload } from '../face-recognition-upload';

import { UploadFacePhotoMutation } from '../../../mutations';

import { FormattedMessageWrappedInSpan } from '../../misc';
import { trackEvent } from '../../../utils/ga-tracking';

import {
  FACTOR_EMAIL,
  FACTOR_DATE_OF_BIRTH,
  FACTOR_YEAR_OF_BIRTH,
  FACTOR_CODE,
  MAX_PHOTO_UPLOAD_DIMENSION,
  MAX_PHOTO_UPLOAD_SIZE,
  MAIN_SEARCH_BIB,
  MAIN_SEARCH_FACE
} from '../../../utils/variables';
import {
  getCustomerKey,
  setCustomerKey,
  setToLocalStorage,
  getFromLocalStorage,
  removeFromLocalStorage,
} from '../../../utils/browser-storage';

import 'whatwg-fetch';

////////////////////////////////////////////
// TODO MISSING TEST CASES:
// - Ref in class component
// - maxLength validates input properly (year)
////////////////////////////////////////////

const localeMap = {
  de: deLocale,
  en: enLocale,
  fr: frLocale,
  nl: nlLocale,
  it: itLocale,
  es: esLocale,
  zh: zhLocale,
  pt: ptLocale,
};

const ENTER_KEY = 'Enter';
const SECOND_FACTOR = 'secondFactor';
const START_NUMBER = 'startNumber';

const cookies = new Cookies();

// NOTE: Pay attention when changing this component to
//       functional component as we had race conditions
//       when trying that the last time.
//       Inside `handleSearchClick` we call a function
//       that sets a state in the parent component and
//       afterwards we want to redirect.
//       In a functional component this redirect call
//       does not have the new startnumber set and
//       therefor fails.
class StartNumberSearch extends Component {
  constructor(props) {
    super(props);

    const { info, searchType } = props;

    //only show instructions if we start with an empty search field / no face record and when warnText is there for the event
    this.state = {
      showSpinner: false,
      recognitionPhoto: null,
      isStartNumberSearch:
        (searchType === 'auto' && info.mainSearch === MAIN_SEARCH_BIB) || searchType === 'startnumber',
      isEmailSecondFactor:
        info.secondFactorType && info.secondFactorType.type === FACTOR_EMAIL,
      extendedSearchInstructionsOpen: false,
      secondFactorInputIsFocused: false,
      showStartNumberError: false,
      showSecondFactorError: false,
      tempInputValue: '',
      locale: getFromLocalStorage('language'),
      privacyPolicyAgreed: false,
      identityConfirmed: false,
      showPrivacyTooltip: false,
      openAccessForFaceCodeEvent: false,
      faceSearchId: null,
      showCookieOptionDialog: false,
    };

    this.handleBlur = this.handleBlur.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
    this.handleSearchClick = this.handleSearchClick.bind(this);
    this.handleSearchClickNavigation = this.handleSearchClickNavigation.bind(this);
    this.handleDatePickerDismiss = this.handleDatePickerDismiss.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleExtendedSearchHintClick = this.handleExtendedSearchHintClick.bind(this);
    this.handleRecognitionPhotoChangeOnWebcam =
      this.handleRecognitionPhotoChangeOnWebcam.bind(this);
    this.handleRecognitionPhotoChange = this.handleRecognitionPhotoChange.bind(this);
    this.handlePrivacyPolicyChange = this.handlePrivacyPolicyChange.bind(this);
    this.handleIdentityConfirmedChange = this.handleIdentityConfirmedChange.bind(this);
    this.checkIfResizingNeeded = this.checkIfResizingNeeded.bind(this);
    this.resizeImage = this.resizeImage.bind(this);
    this.readFileWithoutResizing = this.readFileWithoutResizing.bind(this);
    this.saveFaceSearchIDAsCookie = this.saveFaceSearchIDAsCookie.bind(this);
    this.saveCustomerKeyAsAsCookie = this.saveCustomerKeyAsAsCookie.bind(this);
    this.disableFaceIDCookiesForEvent = this.disableFaceIDCookiesForEvent.bind(this);
  }

  componentDidMount() {
    const { shouldShowSearchByFaceInstructions } = this.state;
    const {
      startNumber,
      showSearchInstructionsDialog,
      setFormRef,
      isAccessGrantedByValidCodeFaceEvent,
      location,
      info,
      dualSearch,
    } = this.props;
    const isUserInAlternativeSearchAfterCodeValidation =
      Boolean(location?.search) &&
      (location.pathname.includes('dual') ||
        location.pathname.includes('category') ||
        location.pathname.includes('gps') ||
        location.pathname.includes('smartsearch')) &&
      !dualSearch &&
      info.secondFactorType?.type === FACTOR_CODE;
    if (
      isAccessGrantedByValidCodeFaceEvent ||
      isUserInAlternativeSearchAfterCodeValidation
    ) {
      this.setState({ openAccessForFaceCodeEvent: true });
    }
    const faceSearchIDCookie = cookies.get(`faceSearchIdForEvent${this.props.info.sgId}`);
    if (this.startNumberInputRef) {
      // This will not be true if the face recognition search is available. In that case
      // no start number input field will be rendered.
      !startNumber && this.startNumberInputRef?.focus();
    } 

    if (setFormRef) {
      setFormRef(this.searchContainerRef);
    }

    if (
      getFromLocalStorage('userAcceptsSportografWebCookies') === 'true' &&
      getFromLocalStorage('base64-photo') === null &&
      faceSearchIDCookie && faceSearchIDCookie != 'false' &&
      !['search', 'dual'].some((slug) => this.props.match.path.includes(slug))
    ) {
      this.setState({
        showCookieOptionDialog: true,
        faceSearchIDCookie: faceSearchIDCookie,
      });
    }
  }

  handleInputChange(event) {
    const { info, onInputChange, dualSearch } = this.props;
    this.setState({ showStartNumberError: false, showSecondFactorError: false });
    this.props.onShowHowToBuyHint && this.props.onShowHowToBuyHint(false);

    // trim the value
    const trimmedValue = event.target.value.trim();
    if (event.target.name === START_NUMBER) {
      onInputChange(START_NUMBER, trimmedValue);

      // If a second factor exists
      // Update second factor input
      if (!dualSearch && info.secondFactorType) {
        const ck = getCustomerKey(info.sgId, trimmedValue);
        if (ck) {
          onInputChange(SECOND_FACTOR, window.atob(ck));
        } else {
          onInputChange(SECOND_FACTOR, '');
        }
      }
    }

    if (event.target.name === SECOND_FACTOR) {
      // Can only be true if the event has a 2nd factor
      if (info.secondFactorType.type === FACTOR_YEAR_OF_BIRTH) {
        // If the the second factor is the year of birth, only allow numbers
        // isNaN(...) determines if a value does not convert to a number
        if (!isNaN(trimmedValue)) {
          onInputChange(SECOND_FACTOR, trimmedValue);
        }
      } else {
        onInputChange(SECOND_FACTOR, trimmedValue);
      }
    }
  }

  handleKeyPress(event) {
    if (event.key === ENTER_KEY) {
      this.handleSearchClick();
    }
  }

  handleFocus(event) {
    this.setState({
      secondFactorInputIsFocused: event.target.name === SECOND_FACTOR,
    });
  }

  handleBlur(event) {
    if (event.target.name === SECOND_FACTOR) {
      this.setState({ secondFactorInputIsFocused: false });
    }

    if (event.target.name === START_NUMBER) {
      // If the start number input lost focus and has no value (input is empty), insert
      // the start number from the url

      if (this.props.startNumber === '') {
        const { startnumber } = this.props.match.params;
        if (startnumber) {
          this.handleInputChange({
            ...event,
            target: {
              ...event.target,
              value: startnumber,
            },
          });
        }
      }
    }
  }

  handleDatePickerDismiss() {
    // Only called from DatePicker if a second factor is necessary
    this.setState({ secondFactorInputIsFocused: false });
  }

  handleDateChange(date) {
    this.setState({ showStartNumberError: false, showSecondFactorError: false });
    this.props.onShowHowToBuyHint && this.props.onShowHowToBuyHint(false);
    const formattedDate = this._formatDateToString(date);
    this.props.onInputChange(SECOND_FACTOR, formattedDate);
  }

  handleExtendedSearchHintClick() {
    this.setState({ extendedSearchInstructionsOpen: true });
  }

  handlePrivacyPolicyChange() {
    this.setState({
      privacyPolicyAgreed: !this.state.privacyPolicyAgreed,
      showPrivacyTooltip: false,
    });
  }

  handleIdentityConfirmedChange() {
    this.setState({
      identityConfirmed: !this.state.identityConfirmed,
      showPrivacyTooltip: false,
    });
  }

  saveFaceSearchIDAsCookie(faceSearchID) {
    if (localStorage.getItem('userAcceptsSportografWebCookies') === 'true' && cookies.get(`faceSearchIdForEvent${this.props.info.sgId}`) != false) {
      const expirationDate = new Date();
      expirationDate.setFullYear(expirationDate.getFullYear() + 1);
      cookies.set(`faceSearchIdForEvent${this.props.info.sgId}`, faceSearchID, {
        expires: expirationDate,
        path: '/'
      });
    }
  }

  saveCustomerKeyAsAsCookie(customerKey) {
    if (localStorage.getItem('userAcceptsSportografWebCookies') === 'true') {
      const expirationDate = new Date();
      expirationDate.setFullYear(expirationDate.getFullYear() + 1);
      cookies.set(`customerKeyForEvent${this.props.info.sgId}`, customerKey, {
        expires: expirationDate,
      });
    }
  }

  disableFaceIDCookiesForEvent = () => {
    cookies.remove(`faceSearchIdForEvent${this.props.info.sgId}`, { path: '/' });
    this.saveFaceSearchIDAsCookie("false")
  }

  async handleSearchClick() {
    const { info, relay } = this.props;
    const {
      recognitionPhoto,
      privacyPolicyAgreed,
      identityConfirmed,
      isStartNumberSearch,
    } = this.state;
    const isNotCodeEvent =
      !isStartNumberSearch && info.secondFactorType?.type != FACTOR_CODE;
    const isCodeEventAndAccessIsGranted =
      !isStartNumberSearch &&
      info.secondFactorType &&
      info.secondFactorType.type === FACTOR_CODE &&
      this.state.openAccessForFaceCodeEvent;

    if ((isNotCodeEvent || isCodeEventAndAccessIsGranted) && recognitionPhoto === null) {
      const faceRecoInput = document.getElementById('faceRecoInput');
      if (faceRecoInput) {
        faceRecoInput.click();
      }
      return;
    }

    if (
      (isNotCodeEvent || isCodeEventAndAccessIsGranted) &&
      (!privacyPolicyAgreed || !identityConfirmed)
    ) {
      this.setState({ showPrivacyTooltip: true });
      return;
    }
    // Show spinner after search button click
    this.setState({ showSpinner: true });

    // Using face recognition
    if (recognitionPhoto !== null) {
      setToLocalStorage('base64-photo', recognitionPhoto);
      const blob = await this._decodeToFile(recognitionPhoto);

      this.props.onRequestStart();

      UploadFacePhotoMutation.commit(
        relay.environment,
        blob,
        info.sgId,
        (error, _startNumber) => {
          // Hide spinner before navigating to search results
          this.setState({ showSpinner: false });
          if (!error) {
            this.props.onInputChange(START_NUMBER, _startNumber);
            this.saveFaceSearchIDAsCookie(_startNumber);
            this.handleSearchClickNavigation();
            this.props.onRequestStop();
          } else {
            this.props.onRequestError(error);
          }
        }
      );
    } else {
      // hide spinner after request is done
      this.setState({ showSpinner: false });
      this.handleSearchClickNavigation();
    }
  }

  handleSearchClickNavigation() {
    const {
      info,
      startNumber,
      secondFactor,
      onSearchTrigger,
      dualSearch,
      codeSecondFactorForFaceEvent,
    } = this.props;

    if (startNumber === '' && info.secondFactorType?.type != FACTOR_CODE) {
      this.setState({ showStartNumberError: true });
      this.startNumberInputRef?.focus();
    } else if (
      !dualSearch &&
      info.secondFactorType &&
      info.secondFactorType.type != FACTOR_EMAIL
    ) {
      // If the start number has already been searched for, the value of the 'secondFactor'
      // can be ignored

      const customerKey = getCustomerKey(info.sgId, startNumber);
      if (customerKey) {
        // Whenever a new search is triggered, the date should be displayed that matches
        // the start number.
        this.props.onInputChange(SECOND_FACTOR, window.atob(customerKey));
        this.saveCustomerKeyAsAsCookie(customerKey);
        this._navigateToSearchPath(customerKey);
        onSearchTrigger && onSearchTrigger();
      } else if (this.state.openAccessForFaceCodeEvent) {
        this._navigateToSearchPath(codeSecondFactorForFaceEvent);
      } else if (secondFactor === '') {
        this.setState({ showSecondFactorError: true });
        if (
          info.secondFactorType &&
          info.secondFactorType.type !== FACTOR_DATE_OF_BIRTH
        ) {
          this.secondFactorInputRef?.focus();
        }
      } else {
        const encodedSecondFactor = window.btoa(secondFactor);
        this._navigateToSearchPath(encodedSecondFactor);
        onSearchTrigger && onSearchTrigger();

        // Refetch the graphql query to get the 'secondFactorEventSalt'
        // INFO: Not necessary at the moment
        // relay.refetch({ individualPart: startNumber }, null, () => {
        //   // If this callback is beeing called, the new props are available
        //   // Encode the second factor in base64
        // });
      }
    } else {
      this._navigateToSearchPath();
      onSearchTrigger && onSearchTrigger();
    }

    trackEvent('Event', 'Search', 'Search for start number button');
  }

  handleRecognitionPhotoChangeOnWebcam(imgSrc) {
    this.setState({ recognitionPhoto: imgSrc });
    this.props.onShowHowToBuyHint && this.props.onShowHowToBuyHint(false);
    this.props.onInputChange(START_NUMBER, '');
  }

  // Function to check if resizing is needed based on image dimensions
  checkIfResizingNeeded = async (selectedPhoto) => {
    if (selectedPhoto.size > MAX_PHOTO_UPLOAD_SIZE) {
      return true;
    } else {
      const imageToMeasure = new Image();
      return new Promise((resolve) => {
        imageToMeasure.onload = () => {
          const width = imageToMeasure.width;
          const height = imageToMeasure.height;
          resolve(
            width > MAX_PHOTO_UPLOAD_DIMENSION || height > MAX_PHOTO_UPLOAD_DIMENSION
          );
        };
        const url = URL.createObjectURL(selectedPhoto);
        imageToMeasure.src = url;
      });
    }
  };

  resizeImage = async (selectedPhoto) => {
    return new Promise((resolve) => {
      Resizer.imageFileResizer(
        selectedPhoto,
        MAX_PHOTO_UPLOAD_DIMENSION,
        MAX_PHOTO_UPLOAD_DIMENSION,
        'JPEG',
        70,
        0,
        (base64Photo) => {
          resolve(base64Photo);
        }
      );
    });
  };

  readFileWithoutResizing = (selectedPhoto) => {
    return new Promise((resolve, reject) => {
      let reader = new FileReader();
      reader.onload = () => {
        const imageData = reader.result;
        this.setState({ recognitionPhoto: imageData });
        this.props.onInputChange(START_NUMBER, '');
        resolve(imageData);
      };
      reader.readAsDataURL(selectedPhoto);
    });
  };

  async handleRecognitionPhotoChange(event) {
    this.props.onShowHowToBuyHint && this.props.onShowHowToBuyHint(false);
    const selectedPhoto = event.target.files[0];
    // 'selectedPhoto' can be undefined if no file has been selected in the file manager
    if (selectedPhoto) {
      const resizingNeeded = await this.checkIfResizingNeeded(selectedPhoto);
      if (resizingNeeded) {
        const resizedPhoto = await this.resizeImage(selectedPhoto);
        this.setState({ recognitionPhoto: resizedPhoto });
        this.props.onInputChange(START_NUMBER, '');
      } else {
        this.readFileWithoutResizing(selectedPhoto);
      }
    }
  }


  handleExtendedSearchInstructionsCanceled = () => {
    this.setState({ extendedSearchInstructionsOpen: false });
  };

  handleExtendedSearchInstructionsAccepted = () => {
    this.setState({ extendedSearchInstructionsOpen: false });
    this.props.onExtendedSearchHintClosed();
  };

  handleNavigateToSearchPathUsingCookies() {
    const { history, info } = this.props;
    const faceSearchIdFromCookies = cookies.get(`faceSearchIdForEvent${info.sgId}`);
    const customerKeyFromCookies = cookies.get(`customerKeyForEvent${info.sgId}`);
    let destinationUrl = `/event/${info.sgId}/search/${faceSearchIdFromCookies}`;
    if (customerKeyFromCookies) {
      setCustomerKey(info.sgId, faceSearchIdFromCookies, customerKeyFromCookies);
      destinationUrl = `${destinationUrl}/${customerKeyFromCookies}`;
    }
    history.replace(destinationUrl);
  }

  _navigateToSearchPath(customerKey) {
    const { history, match, info, startNumber, secondFactor, dualSearch } = this.props;
    const targetSlug = dualSearch ? 'dual' : 'search';
    let destinationUrl = '';
    const is2FACodeForFaceEvent =
      info.secondFactorType &&
      info.secondFactorType.type === FACTOR_CODE &&
      info.mainSearch === MAIN_SEARCH_FACE;

    if (startNumber === undefined) {
      destinationUrl = `/event/${info.sgId}/${targetSlug}/no-photos-found`;
    } else {
      destinationUrl = `/event/${info.sgId}/${targetSlug}/${startNumber}`;
    }
    if (customerKey && !is2FACodeForFaceEvent) {
      destinationUrl = `${destinationUrl}/${customerKey}`;
    } else if (secondFactor) {
      //patch for dual search - please do not merge it in one condition
      if (is2FACodeForFaceEvent) {
        if (dualSearch) {
          destinationUrl = `${destinationUrl}/${secondFactor}`;
        }
      } else {
        destinationUrl = `${destinationUrl}/${secondFactor}`;
      }
    }

    if (is2FACodeForFaceEvent) {
      let codeFactor;
      if (!this.props.location?.search) {
        codeFactor = customerKey;
      } else {
        const searchParams = new URLSearchParams(this.props.location.search);
        codeFactor = this.props.match.params.customerKey
          ? this.props.match.params.customerKey
          : searchParams.get('code');
      }

      destinationUrl = `${destinationUrl}?code=${codeFactor}`;
    }

    switch (true) {
      case match.path.startsWith(`/:language/event/:id/${targetSlug}/`):
        history.replace(destinationUrl);
        break;
      default:
        history.push(destinationUrl);
        break;
    }
  }

  _getSecondFactorInputHintText(type) {
    let translationId = null;
    switch (type) {
      case FACTOR_DATE_OF_BIRTH:
        translationId = translations.secondFactorBirthDateHintText;
        break;
      case FACTOR_YEAR_OF_BIRTH:
        translationId = translations.secondFactorYearOfBirthHintText;
        break;
      case FACTOR_CODE:
        translationId = translations.secondFactorCodeHintText;
        break;
      default:
        return null;
    }
    return this.props.intl.formatMessage(translationId);
  }

  _renderSecondFactorTitle(type) {
    switch (type) {
      case FACTOR_DATE_OF_BIRTH:
        return (
          <FormattedMessageWrappedInSpan
            id="startNumberSearch.secondFactor.birthDateType.floatingLabelText"
            defaultMessage="Select your date of birth"
          />
        );
      case FACTOR_YEAR_OF_BIRTH:
        return (
          <FormattedMessageWrappedInSpan
            id="startNumberSearch.secondFactor.yearOfBirthType.floatingLabelText"
            defaultMessage="Enter your year of birth"
          />
        );
      case FACTOR_CODE:
        return (
          <FormattedMessageWrappedInSpan
            id="startNumberSearch.secondFactor.codeType.floatingLabelText"
            defaultMessage="Enter your code"
          />
        );
      default:
        return null;
    }
  }

  _formatDateToString(date) {
    // 01, 02, 03, ... 29, 30, 31
    const dd = (date.getDate() < 10 ? '0' : '') + date.getDate();
    // 01, 02, 03, ... 10, 11, 12
    const mm = (date.getMonth() + 1 < 10 ? '0' : '') + (date.getMonth() + 1);
    // 1970, 1971, ... 2015, 2016, ...
    const yyyy = date.getFullYear();
    return `${dd}-${mm}-${yyyy}`;
  }

  _formatStringToDate(s) {
    if (!s) {
      return null;
    }
    const splitted = s.split('-');
    const dd = Number(splitted[0]);
    const mm = Number(splitted[1]) - 1;
    const yyyy = Number(splitted[2]);
    return new Date(yyyy, mm, dd);
  }

  //return a promise that resolves with a File instance
  _decodeToFile(b64Data, sliceSize = 1024) {
    return new Promise(function (resolve, reject) {
      const byteCharacters = atob(b64Data.split(',')[1]);
      const byteArrays = [];

      for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }

      const blob = new Blob(byteArrays, { type: 'image/*' });
      resolve(blob);
    });
  }

  setFacePhotoFromCache(startNumber) {
    const encodedPhoto = getFromLocalStorage('base64-photo');
    if (startNumber !== '' && encodedPhoto !== null) {
      this.setState({ recognitionPhoto: encodedPhoto });
    } else {
      removeFromLocalStorage('base64-photo');
      return null;
    }
  }

  render() {
    const {
      intl,
      info,
      startNumber,
      secondFactor,
      showSearchHint,
      className,
      dualSearch,
      hasExtendedSearch,
      showHowToBuyHint,
      hasExtendedOptionsVisible
    } = this.props;

    const {
      showSpinner,
      recognitionPhoto,
      secondFactorInputIsFocused,
      showStartNumberError,
      showSecondFactorError,
      showPrivacyTooltip,
      isStartNumberSearch,
      isEmailSecondFactor,
      openAccessForFaceCodeEvent,
    } = this.state;

    const defaultClassName = className || 'col-xs-16 col-sm-6';
    const secondFactorTypeForEvent = info.secondFactorType && info.secondFactorType.type;
    const isFaceSearchEvent = info.mainSearch === MAIN_SEARCH_FACE;
    const isBibSearchEvent = info.mainSearch === MAIN_SEARCH_BIB;

    if (secondFactorTypeForEvent) {
      var secondFactorInputType;
      switch (secondFactorTypeForEvent) {
        case FACTOR_CODE:
          secondFactorInputType = 'password';
          break;
        default:
          secondFactorInputType = 'text';
      }
    }

    return (
      <div className={defaultClassName}>
        <div
          className={classNames('event-page-search-container', {
            'second-factor-container':
              !isStartNumberSearch &&
              secondFactorTypeForEvent === FACTOR_CODE &&
              !openAccessForFaceCodeEvent,
          })}
          ref={(div) => (this.searchContainerRef = div)}
        >
          {!isStartNumberSearch &&
            secondFactorTypeForEvent === FACTOR_CODE &&
            !openAccessForFaceCodeEvent && (
              <>
                <div className={classNames('event-page-search-container-label ml-15 mr-15')}>
                  <FormattedMessageWrappedInSpan
                    id="startNumberSearch.codeFaceFactorLabelText"
                    defaultMessage="This is a secured event"
                  />
                </div>
                <div className="ml-15 mr-15 mb-15">
                  <FormattedMessageWrappedInSpan
                    id="startNumberSearch.codeFaceFactorSubtitle"
                    defaultMessage="This event is locked. You can search for images only after entering your access code, which should have been provided by event's organizer."
                  />
                </div>
              </>
            )}
          {((!isStartNumberSearch && secondFactorTypeForEvent != FACTOR_CODE) ||
            openAccessForFaceCodeEvent) && (
            <FaceRecognitionUpload
              imgSrc={
                recognitionPhoto !== null && recognitionPhoto !== undefined
                  ? recognitionPhoto
                  : this.setFacePhotoFromCache(startNumber)
              }
              onChangeUpload={this.handleRecognitionPhotoChange}
              onChangeWebcam={this.handleRecognitionPhotoChangeOnWebcam}
              onChangePrivacyPolicy={this.handlePrivacyPolicyChange}
              onChangeIdentityConfirmation={this.handleIdentityConfirmedChange}
              useWebcam={info.faceWebcamOnly}
            />
          )}
          {isStartNumberSearch && (
            <Fragment>
              <div
                ref={(div) => (this.startNumberInstructionRef = div)}
                className={classNames('event-page-search-container-label ml-15 mr-15', {
                  error: showStartNumberError,
                })}
              >
                <FormattedMessageWrappedInSpan
                  id="startNumberSearch.floatingLabelText"
                  defaultMessage="Enter your bib number"
                />
              </div>
              <div
                className={classNames('startnumber-search-textfield', {
                  'input-edit-icon': startNumber === '',
                })}
              >
                <div>
                  <input
                    autoComplete="off"
                    name={START_NUMBER}
                    onChange={this.handleInputChange}
                    onBlur={this.handleBlur}
                    onKeyPress={this.handleKeyPress}
                    onFocus={this.handleFocus}
                    placeholder="0000"
                    ref={(input) => (this.startNumberInputRef = input)}
                    type="text"
                    value={startNumber}
                  />
                </div>
              </div>
            </Fragment>
          )}
          {!dualSearch &&
            info.secondFactorType &&
            !isEmailSecondFactor &&
            !openAccessForFaceCodeEvent && (
              <Fragment>
                <div
                  className={classNames('event-page-search-container-label startnumber-search-label ml-15 mr-15', {
                    error: showSecondFactorError,
                  })}
                >
                  {this._renderSecondFactorTitle(secondFactorTypeForEvent)}
                </div>
                {secondFactorTypeForEvent === FACTOR_DATE_OF_BIRTH && (
                  <MuiPickersUtilsProvider
                    utils={DateFnsUtils}
                    locale={localeMap[this.state.locale]}
                  >
                    <DatePicker
                      autoOk={true}
                      disableFuture
                      cancelLabel={intl.formatMessage(
                        translations.datePickerCancelButton
                      )}
                      classes={{
                        root: classNames(
                          'startnumber-search-textfield',
                          'second-factor',
                          {
                            focused: secondFactorInputIsFocused,
                            'input-edit-icon': secondFactor === '',
                          }
                        ),
                      }}
                      format="dd-MM-yyyy"
                      views={['year', 'month', 'date']}
                      fullWidth={true}
                      InputProps={{ disableUnderline: true }}
                      minDate={new Date(1900, 0, 1)}
                      maxDate={new Date()}
                      name={SECOND_FACTOR}
                      okLabel={intl.formatMessage(translations.datePickerAcceptButton)}
                      onClose={this.handleDatePickerDismiss}
                      onChange={this.handleDateChange}
                      onFocus={this.handleFocus}
                      openTo="year"
                      placeholder={this._getSecondFactorInputHintText(
                        FACTOR_DATE_OF_BIRTH
                      )}
                      value={this._formatStringToDate(secondFactor)}
                    />
                  </MuiPickersUtilsProvider>
                )}
                {secondFactorTypeForEvent !== FACTOR_DATE_OF_BIRTH && (
                  <div
                    className={classNames(
                      'startnumber-search-textfield',
                      'second-factor',
                      {
                        focused: secondFactorInputIsFocused,
                        'input-edit-icon': secondFactor === '',
                      }
                    )}
                  >
                    <div>
                      <input
                        autoComplete="off"
                        maxLength={
                          info.secondFactorType &&
                          info.secondFactorType.type === FACTOR_YEAR_OF_BIRTH
                            ? 4
                            : undefined
                        }
                        name={SECOND_FACTOR}
                        onBlur={this.handleBlur}
                        onChange={this.handleInputChange}
                        onKeyPress={this.handleKeyPress}
                        onFocus={this.handleFocus}
                        placeholder={this._getSecondFactorInputHintText(
                          info.secondFactorType && info.secondFactorType.type
                        )}
                        ref={(input) => (this.secondFactorInputRef = input)}
                        value={secondFactor}
                        type={secondFactorInputType}
                      />
                    </div>
                  </div>
                )}
                <GDPRHint secondFactorNote={true} className="mb-15 ml-15 mr-15" />
              </Fragment>
            )}
          {showSpinner && (
            <div className="startnumber-search-spinner container-full-width center-xs">
              <CircularProgress />
            </div>
          )}
          {!showSpinner && (
            <Tooltip
              title={
                showPrivacyTooltip ? (
                  <FormattedMessageWrappedInSpan
                    id="startNumberSearch.faceSearch.dataProcessingHint"
                    defaultMessage="Please confirm that you consent to the processing of your data."
                  />
                ) : (
                  <FormattedMessageWrappedInSpan
                    id="eventPage.howToBuyHint.searchForm"
                    defaultMessage="Please enter your details in the form above and then click this button to see and buy your photos."
                  />
                )
              }
              open={showHowToBuyHint || showPrivacyTooltip}
              arrow
            >
              <button
                className={classNames('primary', 'fullwidth')}
                onClick={this.handleSearchClick}
              >
                {((isFaceSearchEvent &&
                  !dualSearch &&
                  secondFactorTypeForEvent != FACTOR_CODE) ||
                  (dualSearch &&  isBibSearchEvent) ||
                  (secondFactorTypeForEvent === FACTOR_CODE &&
                    openAccessForFaceCodeEvent &&
                    isFaceSearchEvent)) && (
                  <FormattedMessageWrappedInSpan
                    id="FaceRecognitionUpload.searchButton"
                    defaultMessage="Search with selfie"
                  />
                )}
                {secondFactorTypeForEvent === FACTOR_CODE &&
                  isFaceSearchEvent &&
                  !dualSearch &&
                  !openAccessForFaceCodeEvent && (
                    <FormattedMessageWrappedInSpan
                      id="startNumberSearch.codeFactorSearchButton"
                      defaultMessage="Validate"
                    />
                  )}
                {isStartNumberSearch && (
                  <FormattedMessageWrappedInSpan
                    id="startNumberSearch.searchButton"
                    defaultMessage="Search"
                  />
                )}
              </button>
            </Tooltip>
          )}
        </div>
        <GDPRHint className="mt-15 mb-15 ml-15 mr-15" />
        {showSearchHint && (
          <>
            {isBibSearchEvent && info.hostResultUrl && (
              <div className="startnumber-search-hint">
                <a href={info.hostResultUrl} target="_blank" rel="noopener noreferrer">
                  <FormattedMessageWrappedInSpan
                    id="startNumberSearch.forgotStartnumberHint"
                    defaultMessage="Forgot your bib number?"
                  />
                </a>
              </div>
            )}
            {isFaceSearchEvent && hasExtendedSearch && hasExtendedOptionsVisible && (
              <div className="startnumber-search-hint">
                <a href="#" onClick={this.handleExtendedSearchHintClick}>
                  <FormattedMessageWrappedInSpan
                    id="FaceRecognitionUpload.alternateSearchHint"
                    defaultMessage="Alternative search options"
                  />
                </a>
              </div>
            )}
            <FaceSearchIDCookieDialog
              open={this.state.showCookieOptionDialog && cookies.get(`faceSearchIdForEvent${this.props.info.sgId}`) != false}
              onClose={() => {
                this.setState({ showCookieOptionDialog: false });
              }}
              onCookieUse={() => this.handleNavigateToSearchPathUsingCookies()}
              onCookieDisableForEvent={this.disableFaceIDCookiesForEvent}
            />
            <ExtendedSearchInstructionsDialog
              open={this.state.extendedSearchInstructionsOpen}
              showGps={info.gps}
              onCancel={this.handleExtendedSearchInstructionsCanceled}
              onAccept={this.handleExtendedSearchInstructionsAccepted}
            />
          </>
        )}
      </div>
    );
  }
}

const translations = defineMessages({
  secondFactorBirthDateHintText: {
    id: 'startNumberSearch.secondFactor.birthDateType.hintText',
    defaultMessage: 'DD-MM-YYYY',
  },
  secondFactorYearOfBirthHintText: {
    id: 'startNumberSearch.secondFactor.yearOfBirthType.hintText',
    defaultMessage: 'YYYY',
  },
  secondFactorCodeHintText: {
    id: 'startNumberSearch.secondFactor.codeType.hintText',
    defaultMessage: 'Enter code here',
  },
  datePickerAcceptButton: {
    id: 'startNumberSearch.secondFactor.datePicker.acceptButton',
    defaultMessage: 'Ok',
  },
  datePickerCancelButton: {
    id: 'startNumberSearch.secondFactor.datePicker.cancelButton',
    defaultMessage: 'Cancel',
  },
});

StartNumberSearch.defaultProps = {
  classNames: undefined,
  secondFactor: '',
  searchType: 'auto',
  showSearchHint: true,
  showSearchInstructionsDialog: false,
  hasExtendedSearch: true,
  dualSearg: false,
  showHowToBuyHint: false,
  isAccessGrantedByValidCodeFaceEvent: false,
};

StartNumberSearch.propTypes = {
  history: PropTypes.object,
  info: PropTypes.object,
  intl: PropTypes.object,
  match: PropTypes.object,
  className: PropTypes.string,
  onInputChange: PropTypes.func,
  onSearchTrigger: PropTypes.func,
  relay: PropTypes.object,
  searchType: PropTypes.oneOf(['auto', 'face', 'startnumber']),
  startNumber: PropTypes.string,
  secondFactor: PropTypes.string,
  showSearchHint: PropTypes.bool,
  showSearchInstructionsDialog: PropTypes.bool,
  hasExtendedSearch: PropTypes.bool,
  dualSearch: PropTypes.bool,
  onExtendedSearchHintClosed: PropTypes.func,
  onRequestStart: PropTypes.func,
  onRequestStop: PropTypes.func,
  onRequestError: PropTypes.func,
  setFormRef: PropTypes.func,
  showHowToBuyHint: PropTypes.bool,
  onShowHowToBuyHint: PropTypes.func,
  isAccessGrantedByValidCodeFaceEvent: PropTypes.bool,
  codeSecondFactorForFaceEvent: PropTypes.string,
  location: PropTypes.object,
  hasExtendedOptionsVisible: PropTypes.bool,
};

export { StartNumberSearch };

export default createRefetchContainer(
  withRouter(withRequestHandler(injectIntl(StartNumberSearch))),
  {
    info: graphql`
      fragment StartNumberSearch_info on Event {
        id
        gps
        mainSearch
        searchDual
        secondFactorEventSalt(individualPart: $individualPart)
        secondFactorType {
          type
        }
        sgId
        hostResultUrl
        faceWebcamOnly
      }
    `,
  },
  graphql`
    query StartNumberSearchRefetchQuery($eventId: ID!, $individualPart: String) {
      viewer {
        event(id: $eventId) {
          secondFactorEventSalt(individualPart: $individualPart)
          ...StartNumberSearch_info
        }
      }
    }
  `
);
