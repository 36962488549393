import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchQuery } from 'react-relay';
import { graphql } from 'react-relay';
import { useDispatch } from 'react-redux';

import environment from '../../environment';

const numberOfItemsQuery = graphql`
  query cartSliceQuery {
    viewer {
      cart {
        lineItems {
          id
        }
        id
      }
    }
  }
`;

export const fetchNumberOfProducts = createAsyncThunk(
  'navbar/numberOfItemsQuery',
  async () => {
    try {
      // ignore warning, without await it does not work
      const response = await fetchQuery(environment, numberOfItemsQuery, {});
      return response?.viewer?.cart?.lineItems?.length || 0;
    } catch (error) {
      return state.cart.itemsInCart;
    }
  }
);

const initialState = {
  itemsInCart: 0,
};

export const cartSlice = createSlice({
  name: 'cart',
  initialState,
  // basic reducers (working on local state) for optimistic UI updates
  reducers: {
    optimisticAddtemToCart: (state) => {
      state.itemsInCart += 1;
    },
    optimisticRemoveItemFromCart: (state) => {
      if (state.itemsInCart > 0) {
        state.itemsInCart -= 1;
      }
    },
    optimisticClearCart: (state) => {
      state.itemsInCart = 0;
    },
  },
  // reducers for async operations
  extraReducers: (builder) => {
    builder.addCase(fetchNumberOfProducts.fulfilled, (state, action) => {
      state.itemsInCart = action.payload;
    });
  },
});

export const selectItemsInCart = (state) => state.cart.itemsInCart;

export const {
  optimisticAddtemToCart,
  optimisticRemoveItemFromCart,
  optimisticClearCart,
} = cartSlice.actions;

export default cartSlice.reducer;

// custom hooks for optimistic UI updates
// how to use:
// assign to const: const addOptimisticItemToCart = useOptimisticAddItemToCart()
// call inside mutation: addOptimisticItemToCart()
export const useOptimisticAddItemToCart = () => {
  const dispatch = useDispatch();
  return () => {
    dispatch(optimisticAddtemToCart());
  };
};

export const useOptimisticRemoveItemFromCart = () => {
  const dispatch = useDispatch();
  return () => {
    dispatch(optimisticRemoveItemFromCart());
  };
};

export const useOptimisticClearCart = () => {
  const dispatch = useDispatch();
  return () => {
    dispatch(optimisticRemoveItemFromCart());
  };
};
