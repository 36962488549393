import React, { useState, useContext } from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import findIndex from 'lodash.findindex';
import { useHistory } from 'react-router-dom';

import ItemAddedDialog from '../dialogs/ItemAddedDialog';
import MultiselectModeOptionDialog from '../dialogs/MultiselectModeOptionDialog';
import LoadingDialog from '../dialogs/LoadingDialog';
import LoadMoreButton from './LoadMoreButton';
import MediaListItem from './MediaListItem';
import MediaListMasonry from './MediaListMasonry';
import ThumbnailPreview from '../thumbnail/ThumbnailPreview';
import withRequestHandler from '../../hoc/withRequestHandler';
import FixedElementsPortal from '../../misc/FixedElementsPortal';
import MultiselectRecheckModeActiveInformation from '../informations/MultiselectRecheckModeActiveInformation';
import RequestRecheckView from '../thumbnail/RequestRecheckView';
import CustomDialog from '../dialogs/CustomDialog';
import RecheckSuccessDialog from '../dialogs/RecheckSuccessDialog';

import AddFotoFlatToCartMutation from '../../../mutations/AddFotoFlatToCartMutation';
import AddSingleFotoToCartMutation from '../../../mutations/AddSingleFotoToCartMutation';
import RequestRechecksMutation from '../../../mutations/RequestRechecksMutation';

import { getFromLocalStorage, setToLocalStorage } from '../../../utils/browser-storage';
import { getAppLanguage } from '../../../intl/languages';
import { useOptimisticAddItemToCart, useOptimisticRemoveItemFromCart } from '../../../store/slices/cartSlice';

import {
  FOTO_FLAT,
  SINGLE_PHOTO,
  PARTICIPANT,
  IMPRESSION,
  ACCOUNT,
  RECHECK,
} from '../../../utils/variables';
import { getProduct, includesVideo } from '../../../utils/common';
import { HelpdeskWidgetContext } from '../HelpdeskWidget';

const MediaList = (props) => {
  const [slideIndex, setSlideIndex] = useState(0);
  const [email, setEmail] = useState(getFromLocalStorage('email') || '');
  const [updatedCart, setUpdatedCart] = useState(null);
  const [showThumbnailPreview, setShowThumbnailPreview] = useState(false);
  const [showRecheckView, setShowRecheckView] = useState(false);
  const [rechecks, setRechecks] = useState([]);
  const [showItemAddedDialog, setShowItemAddedDialog] = useState(false);
  const [showMultiselectOptionDialog, setShowMultiselectOptionDialog] = useState(false);
  const [wasMultiselectModeRejected, setWasMultiselectModeRejected] = useState(false);
  const [showImagesInMultiselectMode, setShowImagesInMultiselectMode] = useState(false);
  const [imagesSelectedToMultipleRecheck, setimagesSelectedToMultipleRecheck] = useState(
    []
  );
  const [showRecheckForm, setShowRecheckForm] = useState(false);
  const [isRecheckRequestSent, setIsRecheckRequestSent] = useState(false);
  const [openRecheckSuccessDialog, setOpenSRecheckuccessDialog] = useState(false);
  const [numberOfRechecksSent, setNumberOfRecheckSent] = useState(1);

  const [hlpDskState, hlpDskDispatch] = useContext(HelpdeskWidgetContext);
  const [lastUserInputStartNumber, setLastUserInputStartNumber] = useState(null);
  const hasVideo = props.listType !== 'account' && includesVideo(props.offer?.products);
  const fotoFlat =
    props.listType !== 'account' ? getProduct(props.offer?.products, FOTO_FLAT) : null;
  const singlePhoto =
    props.listType !== 'account' ? getProduct(props.offer?.products, SINGLE_PHOTO) : null;

  const history = useHistory();
  const addOptimisticItemToCart = useOptimisticAddItemToCart()
  const removeOptimisticItemFromCart = useOptimisticRemoveItemFromCart()

  ////////////////////////////////////////////
  // TODO tests cases:
  // - mutations in cart (updater error)
  // - dialog interactivity after item was added to cart - related to above
  ////////////////////////////////////////////

  // no longer valid in 2023 - problem occured in 2019, variables derived from props used instead of useEffect (above)
  // // Direct call of `useEffect` is required for test function
  // // mock to propely spy on this function and call the `useEffect`
  // // which is currently not supporter by the test framework on its
  // // own.
  // // React.useEffect(() => {
  // //   if (props.listType !== 'account') {
  // //     setFotoFlat(getProduct(props.offer.products, FOTO_FLAT));
  // //     setSinglePhoto(getProduct(props.offer.products, SINGLE_PHOTO));
  // //   }
  // //   if (props.listType !== 'account') {
  // //     if (includesVideo(props.offer.products)) {
  // //       setHasVideo(true);
  // //     }
  // //   }
  // // }, []);

  const handleCloseItemAddedDialog = () => {
    setShowItemAddedDialog(false);
  };

  const handleOpenThumbnailPreview = (mediaId) => {
    const index = findIndex(props.media.edges, (edge) => edge.node.id === mediaId);
    if (index >= props.media.edges.length - 5) {
      if (props.hasMore) {
        props.onLoadMore();
      }
    }
    setShowThumbnailPreview(true);
    setSlideIndex(index);
  };

  const handleCloseThumnailPreview = () => {
    setShowThumbnailPreview(false);
    setShowRecheckView(false);
  };

  const handleShowRecheckView = () => {
    if (!wasMultiselectModeRejected) {
      setShowMultiselectOptionDialog(true);
    } else {
      setShowRecheckView(true);
    }
  };

  const handleHideRecheckView = () => {
    setShowRecheckView(false);
  };

  const handleSlide = (index) => {
    if (index >= props.media.edges.length - 5) {
      if (props.hasMore) {
        props.onLoadMore();
      }
    }
    setSlideIndex(index);
  };

  const handleBuyFotoFlat = () => {
    addOptimisticItemToCart();
    props.onRequestStart();
    const { startnumber, relay } = props;

    hlpDskDispatch({
      type: 'addToHistory',
      payload: `buy FF: ${startnumber}`,
    });

    AddFotoFlatToCartMutation.commit(
      relay.environment,
      fotoFlat.id,
      startnumber,
      (error, cart) => {
        if (error) {
          removeOptimisticItemFromCart()
          props.onRequestError(error);
        } else {
          setUpdatedCart(cart);
          setShowItemAddedDialog(true);
        }
        props.onRequestStop();
      }
    );
  };

  const handleBuySinglePhoto = () => {
    addOptimisticItemToCart();
    props.onRequestStart();
    const { relay, media } = props;

    hlpDskDispatch({
      type: 'addToHistory',
      payload: `buy single photo: ${media.edges[slideIndex].node.sgId}`,
    });
    AddSingleFotoToCartMutation.commit(
      relay.environment,
      singlePhoto.id, // product id
      media.edges[slideIndex].node.id, // foto media id
      (error, cart) => {
        if (error) {
          removeOptimisticItemFromCart()
          props.onRequestError(error);
        } else {
          setUpdatedCart(cart);
          setShowItemAddedDialog(true);
        }
        props.onRequestStop();
      }
    );
  };

  const handleRequestRechecks = (mediaIds, startnumber, email) => {
    hlpDskDispatch({
      type: 'addToHistory',
      payload: `recheck: ${startnumber} on ${mediaIds}`,
    });

    props.onRequestStart();
    setShowRecheckView(false);
    const { relay, media } = props;
    RequestRechecksMutation.commit(
      relay.environment,
      email,
      getAppLanguage(),
      mediaIds,
      startnumber,
      (error, status) => {
        if (!error) {
          setNumberOfRecheckSent(imagesSelectedToMultipleRecheck.length);
          setToLocalStorage('email', email);
          setEmail(email);
          setRechecks([...rechecks, ...mediaIds]);
          setShowRecheckForm(false);
          setimagesSelectedToMultipleRecheck([]);
          setIsRecheckRequestSent(true);
          setOpenSRecheckuccessDialog(true);
        } else {
          props.onRequestError(error);
        }
        props.onRequestStop();
      }
    );
  };

  const updateimagesSelectedToMultipleRecheck = (item) => {
    setimagesSelectedToMultipleRecheck((prevState) => {
      if (prevState.includes(item)) {
        return prevState.filter((i) => i !== item);
      } else {
        return [...prevState, item];
      }
    });
  };

  const _getFotoFlatOffer = () => {
    const { offer } = props;

    if (_shouldFotoFlatBeDisplayed()) {
      const fotoFlatProduct = getProduct(offer.products, FOTO_FLAT);
      return {
        id: fotoFlatProduct.id,
        price: fotoFlatProduct.price,
        voucherCampaigns: offer.voucherCampaigns,
      };
    }
    return null;
  };

  const _shouldFotoFlatBeDisplayed = () => {
    const { listType, offer, recheckHasOrder, showOfferHintForStillProcessingMedia } =
      props;

    let fotoFlatVisible = ![PARTICIPANT, IMPRESSION, RECHECK].includes(listType);
    if (fotoFlatVisible) {
      return null;
    }

    const fotoFlat = getProduct(offer.products, FOTO_FLAT);
    const singlePhoto = getProduct(offer.products, SINGLE_PHOTO);

    // Without a flat, there is no offer!
    if (!fotoFlat) {
      return false;
    }

    // When an event does not offer a single photo product the FotoFlat should definetly
    // be displayed

    if (listType === RECHECK) {
      if (!recheckHasOrder) {
        return true;
      }
    } else {
      // PARTICIPANT or IMPRESSION
      if (!singlePhoto) {
        return true;
      }

      // always show while media are processing to allow to buy
      if (showOfferHintForStillProcessingMedia) {
        return true;
      }

      // avoid division by zero
      if (singlePhoto.price.amount !== 0) {
        // The Foto-Flat offer should only be shown if the price of found photos for a
        // participant in their sum is higher than the price of a Foto-Flat
        let minCountNecessary = fotoFlat.price.amount / singlePhoto.price.amount;
        return props.participantMediaCount >= minCountNecessary;
      }
    }
    return false;
  };

  const {
    eventHasSearchByFace,
    eventHasSecondFactor,
    media,
    hasMore,
    hasMorePhotosButton,
    startNumbers,
    isFetching,
    isLoading,
    restartState,
    participantMediaCount,
    impressionMediaCount,
    startnumber,
    eventSgId,
    listType,
    onLoadMore,
    onScrollToImpressions,
    onScrollToVoucher,
    onScrollToVideo,
    onScrollToCertificate,
    onScrollToExtendedSearch,
    onImagesLoaded,
    isFaceSearchEvent,
    hasCertificates,
    setFotoFlatRef,
    hasVideoUrl,
    hasCertificateUrl,
    recheckHasOrder,
    hasApprovedRechecks,
    onOpenRecheckProcedureDialog,
    hasOnlyRejectedRechecks,
    goBackToMainSearchResults,
    eventHasFotoFlat,
    showOfferHintForStillProcessingMedia,
    hasBoughtFotoFlat,
  } = props;

  const fotoFlatOffer = _getFotoFlatOffer();

  const goToSearchResults = () => {
    history.push(`/event/${eventSgId}/search/${startnumber}`);
  };

  const goToAccountEventPage = () => {
    history.push(`/account/event/${eventSgId}`);
  };

  return (
    <>
      <div className="row">
        <div className="col-xs-16 col-sm-12 col-sm-offset-2">
          <MediaListMasonry
            participantMediaCount={participantMediaCount}
            impressionMediaCount={impressionMediaCount}
            fotoFlatOffer={fotoFlatOffer}
            onBuyFotoFlatClick={handleBuyFotoFlat}
            onScrollToImpressions={onScrollToImpressions}
            onScrollToVoucher={onScrollToVoucher}
            onScrollToVideo={onScrollToVideo}
            onScrollToCertificate={onScrollToCertificate}
            onImagesLoaded={onImagesLoaded}
            listType={listType}
            setFotoFlatRef={setFotoFlatRef}
            hasVideo={hasVideo}
            hasCertificates={hasCertificates}
            onScrollToExtendedSearch={onScrollToExtendedSearch}
            showMorePhotosBox={
              hasMorePhotosButton && !hasMore && listType === 'participant'
            }
            hasVideoUrl={hasVideoUrl}
            hasCertificateUrl={hasCertificateUrl}
            recheckHasOrder={recheckHasOrder}
            onGoToSearchResults={goToSearchResults}
            onGoToAccountEventPage={goToAccountEventPage}
            hasApprovedRechecks={hasApprovedRechecks}
            hasOnlyRejectedRechecks={hasOnlyRejectedRechecks}
            onOpenRecheckProcedureDialog={onOpenRecheckProcedureDialog}
            isMainSearchUsed={Boolean(startnumber)}
            goBackToMainSearchResults={goBackToMainSearchResults}
            eventHasFotoFlat={Boolean(fotoFlat)}
            showOfferHintForStillProcessingMedia={showOfferHintForStillProcessingMedia}
          >
            {media.edges.map(({ node: mediaNode }) => (
              <MediaListItem
                key={mediaNode.id}
                item={mediaNode}
                status={mediaNode.recheckStatus}
                isMultiselectModeActive={showImagesInMultiselectMode}
                onClickForMultipleSelect={() =>
                  updateimagesSelectedToMultipleRecheck(mediaNode.sgId)
                }
                isSelectedInMultiselectMode={imagesSelectedToMultipleRecheck.includes(
                  mediaNode.sgId
                )}
                onShowPreview={handleOpenThumbnailPreview}
                isAccountEventPage={listType === ACCOUNT}
                listType={listType}
              />
            ))}
          </MediaListMasonry>
          {hasMore && <LoadMoreButton showSpinner={isFetching} onClick={onLoadMore} />}

          <ThumbnailPreview
            eventHasSearchByFace={eventHasSearchByFace}
            eventHasSecondFactor={eventHasSecondFactor}
            open={!showItemAddedDialog && showThumbnailPreview}
            media={media}
            eventSgId={eventSgId}
            rechecks={rechecks}
            slideIndex={slideIndex}
            startnumber={startnumber}
            fotoFlatProduct={fotoFlatOffer}
            singlePhotoProduct={singlePhoto}
            //to show ff in dual search: showRecheckView={showRecheckView && !isDualMainSearchTyoe}
            showRecheckView={showRecheckView}
            email={email}
            startNumbers={startNumbers}
            isLoading={isLoading}
            listType={listType}
            restartState={restartState}
            onSlide={handleSlide}
            onBuySinglePhoto={handleBuySinglePhoto}
            onBuyFotoFlat={handleBuyFotoFlat}
            onShowRecheckView={handleShowRecheckView}
            onHideRecheckView={handleHideRecheckView}
            onRequestRecheck={handleRequestRechecks}
            onRequestClose={handleCloseThumnailPreview}
            isFaceSearchEvent={isFaceSearchEvent}
            hasCertificates={hasCertificates}
            lastUserInputStartNumber={lastUserInputStartNumber}
            setLastUserInputStartNumber={setLastUserInputStartNumber}
            onGoToSearchResults={goToSearchResults}
            onGoToAccountEventPage={goToAccountEventPage}
            recheckHasOrder={recheckHasOrder}
            numberOfRechecksSent={numberOfRechecksSent}
            hasBoughtFotoFlat={hasBoughtFotoFlat}
          />
          {updatedCart && (
            <ItemAddedDialog
              open={showItemAddedDialog}
              lineItems={updatedCart.lineItems}
              totalPrice={updatedCart.totalPrice}
              onRequestClose={handleCloseItemAddedDialog}
            />
          )}
          {!wasMultiselectModeRejected && showMultiselectOptionDialog && (
            <MultiselectModeOptionDialog
              open={showMultiselectOptionDialog}
              onRefuse={() => {
                setWasMultiselectModeRejected(true);
                setShowRecheckView(true);
              }}
              onAccept={() => {
                setShowImagesInMultiselectMode(true);
                setShowThumbnailPreview(false);
                updateimagesSelectedToMultipleRecheck(media.edges[slideIndex].node.sgId);
              }}
              onDialogClose={() => {
                setShowMultiselectOptionDialog(false);
              }}
            />
          )}
          <LoadingDialog open={isLoading} />
        </div>
      </div>
      {showImagesInMultiselectMode && (
        <FixedElementsPortal>
          <MultiselectRecheckModeActiveInformation
            onAccept={() => setShowRecheckForm(true)}
            onCancel={() => {
              setShowImagesInMultiselectMode(false);
              setimagesSelectedToMultipleRecheck([]);
            }}
            eventHasSearchByFace={eventHasSearchByFace}
            isRecheckRequestSent={isRecheckRequestSent}
            isMultiselectModeActive={showImagesInMultiselectMode}
            isAcceptButtonDisabled={imagesSelectedToMultipleRecheck.length === 0}
            onResetAfterSuccess={() => setIsRecheckRequestSent(false)}
          />
        </FixedElementsPortal>
      )}
      <CustomDialog open={showRecheckForm} onRequestClose={() => setShowRecheckForm(false)}>
        <RequestRecheckView
          isStartNumberInputDisabled={eventHasSecondFactor || eventHasSearchByFace}
          startnumber={startnumber}
          email={email}
          onSubmit={(sn, email) =>
            handleRequestRechecks(imagesSelectedToMultipleRecheck, sn, email)
          }
          onCancel={() => {
            setShowRecheckForm(false);
          }}
          lastUserInputStartNumber={lastUserInputStartNumber}
          setLastUserInputStartNumber={setLastUserInputStartNumber}
          imagesToCheckCount={imagesSelectedToMultipleRecheck.length}
        />
      </CustomDialog>
      <RecheckSuccessDialog
        open={openRecheckSuccessDialog && !wasMultiselectModeRejected}
        onRequestClose={() => setOpenSRecheckuccessDialog(false)}
        eventHasSearchByFace={eventHasSearchByFace}
        numberOfRechecksSent={numberOfRechecksSent}
        onContinueAddingMore={() => {
          setOpenSRecheckuccessDialog(false);
          setShowImagesInMultiselectMode(true);
        }}
      />
    </>
  );
};

MediaList.defaultProps = {
  eventHasSearchByFace: false,
  eventHasSecondFactor: false,
  isRecheckPage: false,
};

MediaList.propTypes = {
  eventHasSearchByFace: PropTypes.bool,
  eventHasSecondFactor: PropTypes.bool,
  media: PropTypes.object,
  offer: PropTypes.object,
  hasMore: PropTypes.bool,
  hasMorePhotosButton: PropTypes.bool,
  isFetching: PropTypes.bool,
  isLoading: PropTypes.bool,
  restartState: PropTypes.string,
  participantMediaCount: PropTypes.number,
  impressionMediaCount: PropTypes.number,
  startnumber: PropTypes.string,
  eventSgId: PropTypes.number,
  startNumbers: PropTypes.array,
  onLoadMore: PropTypes.func,
  onScrollToImpressions: PropTypes.func,
  onScrollToVoucher: PropTypes.func,
  onScrollToVideo: PropTypes.func,
  onScrollToCertificate: PropTypes.func,
  onScrollToExtendedSearch: PropTypes.func,
  onRequestStart: PropTypes.func,
  onRequestStop: PropTypes.func,
  onRequestError: PropTypes.func,
  listType: PropTypes.oneOf([
    'participant',
    'categories',
    'suggestions',
    'dual',
    'gps',
    'impressions',
    'account',
    'recheck',
  ]).isRequired,
  relay: PropTypes.object,
  onImagesLoaded: PropTypes.func,
  isFaceSearchEvent: PropTypes.bool,
  hasCertificates: PropTypes.bool,
  setFotoFlatRef: PropTypes.func,
  hasVideoUrl: PropTypes.bool,
  hasCertificateUrl: PropTypes.bool,
  goBackToMainSearchResults: PropTypes.func,
  eventHasFotoFlat: PropTypes.func,
  recheckHasOrder: PropTypes.bool,
  hasApprovedRechecks: PropTypes.bool,
  hasOnlyRejectedRechecks: PropTypes.bool,
  onOpenRecheckProcedureDialog: PropTypes.func,
  showOfferHintForStillProcessingMedia: PropTypes.bool,
  hasBoughtFotoFlat: PropTypes.bool,
};

export { MediaList };

const WrappedComponent = withRequestHandler(MediaList);

export default createFragmentContainer(injectIntl(WrappedComponent), {
  media: graphql`
    fragment MediaList_media on MediaConnection {
      edges {
        node {
          id
          sgId
          shotDate
          variants {
            name
            url
          }
          recheckStatus
          recheckRequestedAt @include(if: $isRecheckPage)
          ...MediaListItem_item
        }
      }
    }
  `,
  offer: graphql`
    fragment MediaList_offer on Event {
      products {
        id
        mediaCollection {
          name
        }
        price
        type
      }
      voucherCampaigns {
        id
        banner
        name
        shortText
      }
    }
  `,
});
