import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const SpecialHeader = ({ headerType, hideGradients }) => {
  return (
    <>
      <div
        className={classNames(`${headerType}`, `${headerType}_left`, {
          [`${headerType}_top-corner`]: hideGradients,
          [`${headerType}_gradient`]: !hideGradients,
        })}
      ></div>
      <div
        className={classNames(`${headerType}`, `${headerType}_right`, {
          [`${headerType}_top-corner`]: hideGradients,
          [`${headerType}_gradient`]: !hideGradients,
        })}
      ></div>
    </>
  );
};

SpecialHeader.propTypes = {
  headerType: PropTypes.string,
  hideGradients: PropTypes.bool,
};
export default SpecialHeader;
