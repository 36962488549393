/* global UNLEASH_URL */
/* global UNLEASH_CLIENT_KEY */

import '@babel/polyfill';

import '@formatjs/intl-pluralrules/polyfill';
import '@formatjs/intl-pluralrules/dist/locale-data/en';
import '@formatjs/intl-pluralrules/dist/locale-data/de';
import '@formatjs/intl-pluralrules/dist/locale-data/fr';
import '@formatjs/intl-pluralrules/dist/locale-data/nl';
import '@formatjs/intl-pluralrules/dist/locale-data/es';
import '@formatjs/intl-pluralrules/dist/locale-data/it';
import '@formatjs/intl-pluralrules/dist/locale-data/pt';
import '@formatjs/intl-pluralrules/dist/locale-data/zh';

import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route } from 'react-router-dom';

import { MuiThemeProvider } from '@material-ui/core/styles';
import { create } from 'jss';
import {
  StylesProvider,
  createGenerateClassName,
  jssPreset,
} from '@material-ui/core/styles';

import { IntlProvider } from 'react-intl'; // test

import { LanguageProvider, LanguageConsumer } from './components/context/LanguageContext';
import { NotificationDialogProvider } from './components/context/NotificationDialogContext';
import { UserProvider, UserConsumer } from './components/context/UserContext';
import HelpdeskWidget from './components/content/HelpdeskWidget';

import flattenMessages from './utils/flattenMessages';
import { translations } from './intl/languages';
import { marathonFotoTranslations } from './intl/languages';

import App from './App';

import { customMuiTheme } from './customizedMuiTheme';
import { FlagProvider } from '@unleash/proxy-client-react';

// redux
import { setupStore } from './store/store';
import { Provider as ReduxStoreProvider } from 'react-redux';
import { fetchNumberOfProducts } from './store/slices/cartSlice';

const generateClassName = createGenerateClassName();
const jss = create({
  ...jssPreset(),
  // We define a custom insertion point that JSS will look for injecting the styles in the DOM.
  insertionPoint: document.getElementById('jss-insertion-point'),
});

const featureFlagsClientConfig = {
  url: UNLEASH_URL,
  clientKey: UNLEASH_CLIENT_KEY,
  refreshInterval: 15,
  appName: 'sportograf_frontend',
};

// always update number of product in cart on initial render
const store = setupStore();
store.dispatch(fetchNumberOfProducts());

class AppContainer extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <BrowserRouter>
        <ReduxStoreProvider store={store}>
          <StylesProvider jss={jss} generateClassName={generateClassName}>
            <MuiThemeProvider theme={customMuiTheme}>
              <FlagProvider config={featureFlagsClientConfig}>
                <UserProvider>
                  <UserConsumer>
                    {({
                      isLoggedIn,
                      statsCookies,
                      socialCookies,
                      sportografWebCookies,
                      onCookiesChange,
                      isMarathonFoto,
                    }) => (
                      <LanguageProvider isMarathonFoto={isMarathonFoto}>
                        <LanguageConsumer>
                          {({ language, locale }) => (
                            <IntlProvider
                              locale={locale}
                              messages={flattenMessages(
                                translations[language],
                                marathonFotoTranslations[language],
                                isMarathonFoto
                              )}
                            >
                              <HelpdeskWidget>
                                <NotificationDialogProvider>
                                  <Route
                                    path={['/:language/event/:id/', '/:language']}
                                    render={(props) => (
                                      <App
                                        {...props}
                                        language={language}
                                        isLoggedIn={isLoggedIn}
                                        statsCookies={Boolean(statsCookies)}
                                        socialCookies={Boolean(socialCookies)}
                                        sportografWebCookies={Boolean(
                                          sportografWebCookies
                                        )}
                                        onCookiesChange={onCookiesChange}
                                      />
                                    )}
                                  />
                                </NotificationDialogProvider>
                              </HelpdeskWidget>
                            </IntlProvider>
                          )}
                        </LanguageConsumer>
                      </LanguageProvider>
                    )}
                  </UserConsumer>
                </UserProvider>
              </FlagProvider>
            </MuiThemeProvider>
          </StylesProvider>
        </ReduxStoreProvider>
      </BrowserRouter>
    );
  }
}

ReactDOM.render(<AppContainer />, document.getElementById('app'));
